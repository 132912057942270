<template>
	<div>
		<vue-editor
			v-model="html"
			useCustomImageHandler
			:placeholder="placeholder"
			@image-added="onImageAdded"
			@blur="blur"
		></vue-editor>
	</div>
</template>

<script>
import { VueEditor } from "vue2-editor"

export default {
	name: 'RichTextEditor',
	props: {
		content: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: '请输入'
		}
	},
	components: {
		VueEditor
	},
	computed: {
		html: {
			get() {
				return this.content
			},
			set(val) {
				this.$emit('update:content', val)
			}
		}
	},
	data() {
		return { }
	},
	methods: {
		onImageAdded(file, Editor, cursorLocation, resetUploader) {
			const formData = new FormData()
			formData.append('file', file)

			let that = this
			that.$request.post(
				"sysFileUpload",
				true,
				formData,
				function (r) {
					if (r.code == "0") {
						const url = r.data.fileAddr
						Editor.insertEmbed(cursorLocation, "image", url);
						resetUploader()
					} else {
						that.$message.error(r.msg)
					}
				}
			)
		},
		blur() {
			this.$emit('blur')
		}
	}
}
</script>

<style lang="scss" scoped>
/deep/ .ql-editor.ql-blank {
	&::before {
		color: #C0C4CC;
		font-style: inherit;
	}
}
</style>
